import React, { useState, useEffect, useContext, useRef } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
// components
import { useAuthContext } from "../hooks/useAuthContext"
import usePage from "../hooks/usePage"
import useUser from "../hooks/useUser"
import Widgets from "../helpers/widgets"
import { useDocument } from "../hooks/useDocument"
import { StateContext } from "../helpers/StateProvider"
import ReactToPrint from "react-to-print"
import { Share } from "../components/LisitngDetails/ListingDetails.styles"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../assets/icons/facebook-share.svg"
import Twitter from "../assets/icons/twitter-share.svg"
import Linkedin from "../assets/icons/linkedin-share.svg"
import Whatsapp from "../assets/icons/whatsapp-icon.svg"
import telegram from "../assets/icons/telegram_logo_circle_icon.svg"
import email from "../assets/icons/email-envelop.svg"
const IndexPage = props => {
  const { user, authIsReady } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [pageWidgets, setPageWidgets] = usePage("home")
  const [arabic, setArabic] = useState(false)
  const currentUrl = window.location.href
  useEffect(() => {
    setLoading(false)
  }, [pageWidgets])
  const { language, setLanguage } = useContext(StateContext)
  const componentRef = useRef()
  return (
    <>
      {authIsReady && (
        <>
          <>
            <Seo title="about-us" />
            <div
              ref={componentRef}
              className={
                language == false
                  ? "container cards_about-us"
                  : "text-right container cards_about-us"
              }
            >
              <h3>{language == false ? "About us" : "معلومات عنا"}</h3>
              <p>{language == false ? "DOT VIP" : "مرحبًا بكم في مجتمع DOT"}</p>
              <p>
                {language == false
                  ? "Welcome to the DOT VIP community"
                  : "إذا كنت وكيل عقارات أو مطورًا أو مستثمرًا ، فأنت في هذه الصفحة لسبب ما. كل شيء يأتي لسبب ما."}
              </p>
              <p>
                {language == false
                  ? "If you are a real estate agent, developer or investor, you are on this page for a reason. Everything comes for a reason."
                  : "إذا كنت ترغب في جذب المزيد من العملاء المحتملين إلى عملك ، وإغلاق مبيعات محددة مع عملاء أو عملاء محددين ، وإقناع صانعي القرار ليقولوا نعم لاقتراحك ، والتأثير على فرد واحد للشراء منك ، فقد أنشأنا موقعنا على الإنترنت"}
              </p>
              <a href="https://propertypro.vip/">
                <p>
                  {language == false
                    ? "If you want to attract more potential customers to your business, close specific sales with specific clients or customers, convince decision makers to say yes to your proposal, and influence one individual to buy from you, we have created our website"
                    : "ومع ذلك ، نظرًا لأننا نحب الأعمال العقارية ، فإننا مصممون ولدينا إيمان بأن هناك حلًا لكل عائق ، ولم نعتقد أبدًا أنه مستحيل ؛ نحن نؤمن بالمثل ، “حيثما توجد الإرادة ، توجد طريقة ؛” سنجد بهذه الطريقة. نحن ملتزمون ببذل كل جهد ممكن للحفاظ على ازدهار الأعمال."}
                </p>
              </a>
              <p>
                {language == false
                  ? "However, since we love the real estate business, we are determined and have faith that there is a solution to every obstacle, and we have never thought that it is impossible; We believe in the saying, “Where there is a will, there is a way;” We will find this way. We are committed to making every effort to keep the business thriving."
                  : "ومع ذلك ، نظرًا لأننا نحب الأعمال العقارية ، فإننا مصممون ولدينا إيمان بأن هناك حلًا لكل عائق ، ولم نعتقد أبدًا أنه مستحيل ؛ نحن نؤمن بالمثل ، “حيثما توجد الإرادة ، توجد طريقة ؛” سنجد بهذه الطريقة. نحن ملتزمون ببذل كل جهد ممكن للحفاظ على ازدهار الأعمال."}
              </p>
              <p>
                {language == false
                  ? "We have created our website"
                  : "لقد أنشأنا موقعنا "}
              </p>
              <a href="https://propertypro.vip">
                <p>https://propertypro.vip</p>
              </a>
              <p>
                {language == false
                  ? "It is an innovative real estate family business concept based on expertise, originality, creativity, flexibility, transparency and care."
                  : "وهو مفهوم أعمال عائلي عقاري مبتكر يعتمد على الخبرة والأصالة والإبداع والمرونة والشفافية والرعاية."}
              </p>
              <p>
                {language == false
                  ? "It is a free research platform that matches the market and audience needs. We aim to keep this platform friendly, credible, reliable, and loyal to all audiences."
                  : "إنها منصة بحثية مجانية تتوافق مع السوق واحتياجات الجمهور. نحن نهدف إلى الحفاظ على هذه المنصة ودية ، وذات مصداقية ، وموثوقة ، ومخلصة لجميع الجماهير."}
              </p>
              <p>
                {language == false
                  ? "We offer a wide range of real estate focused services ranging in scope from marketing, advertising, consultancy, feasibility studies, brokerage between sellers and buyers, tenants and owners, local price fluctuations, geo-industrial benefits, legal services, engineering services, renovation, maintenance, hospitality infrastructure, schools infrastructure, infrastructure For Gym, Shopping Malls & Malls, Infrastructure Project Management, Budgeting, Building Management, Home Development, Solutions, Furniture, and Interior Design."
                  : "نحن نقدم مجموعة واسعة من الخدمات التي تركز على العقارات والتي يتراوح نطاقها من التسويق والإعلان والاستشارات ودراسات الجدوى والوساطة بين البائعين والمشترين والمستأجرين والمالكين وتقلبات الأسعار المحلية والفوائد الجغرافية الصناعية والخدمات القانونية والهندسة الخدمات ، التجديد ، الصيانة ، البنية التحتية للضيافة ، البنية التحتية للمدارس ، البنية التحتية للصالة الرياضية ، مراكز التسوق ومراكز التسوق ، إدارة مشاريع البنية التحتية ، الميزانية ، إدارة المباني ، تطوير المنزل ، الحلول ، الأثاث ، والتصميم الداخلي."}
              </p>
              <p>
                {language == false
                  ? "We are glad to invite you to find, like, subscribe, or follow us on social media: Facebook, LinkedIn, Twitter, Pinterest, Instagram, YouTube, G+."
                  : "يسعدنا دعوتك لتجد ، الإعجاب ، أو الاشتراك ، أو متابعتنا على وسائل التواصل الاجتماعي: Facebook و LinkedIn و Twitter و Pinterest و Instagram و YouTube و G + ."}
              </p>
              <h4>{language == false ? "our vision" : "رؤيتنا"}</h4>
              <p>
                {language == false
                  ? "We plan to become the most irresistible, most flying, and most profitable real estate website; DOT VIP leads to a better everyday life for buyers, sellers and renters. It aspires to provide motivation and novelty to every real estate broker, agent, dealer, consultant, contractor and salesperson in the world. We use innovation to move solutions and apply them to the real estate market during a recession and teach our audience to become successful."
                  : "نحن نخطط لأن نصبح أكثر مواقع الويب العقارية التي لا تقاوم ، والأكثر طيرانًا ، والأكثر ربحًا ؛ تؤدي DOT VIP إلى حياة يومية أفضل للمشترين والبائعين والمستأجرين. تطمح إلى توفير الحافز والجدة لكل وسيط عقاري ووكيل وتاجر ومستشار ومقاول ومندوب مبيعات في العالم. نحن نستخدم الابتكار لتحريك الحلول وتطبيقها على سوق العقارات خلال فترة الركود وتعليم جمهورنا أن يصبح ناجحًا."}
              </p>
              <p>
                {language == false
                  ? "Keep in mind that if you have any type of real estate, you are a real estate investor. Furthermore, we strive to become the best way to provide outstanding quality, service and value, in order to make every member of the https://propertypro.vip community smile."
                  : "ضع في اعتبارك أنه إذا كان لديك أي نوع من العقارات ، فأنت مستثمر عقاري. علاوة على ذلك ، نسعى إلى أن نصبح أفضل وسيلة لتوفير الجودة والخدمة والقيمة المتميزة ، حتى نجعل كل عضو في مجتمعHttps://propertypro.vip  يبتسم."}
              </p>
              <h4>
                {language == false
                  ? "Our ambitions and goals"
                  : "طموحاتنا وأهدافنا"}
              </h4>
              <p>
                {language == false
                  ? "DOT VIP envisions itself to be the latest real estate platform worldwide. Our internal growth is a mutually beneficial function between us and our audience."
                  : "تتصور DOT VIP نفسها لتصبح أحدث منصة عقارية في جميع أنحاء العالم. نمونا الداخلي هو وظيفة متبادلة المنفعة بيننا وبين جمهورنا."}
              </p>
              <p>
                {language == false
                  ? "We warmly invite you to join our growing “Snowball” community and share our experiences, successes, failures, trials and disappointments. Thus, we look forward to sharing each other's revenue and adding credit to prosper together. Make sure we're here to support and encourage you when you're in the dark."
                  : "ندعوكم بحرارة للانضمام إلى مجتمعنا المتنامي “كرة الثلج” ومشاركة تجاربنا ونجاحاتنا وإخفاقاتنا وتجاربنا وخيباتنا. وبالتالي ، فإننا نتطلع إلى تقاسم عائدات بعضنا البعض وإضافة الائتمان لتحقيق الازدهار معًا. تأكد من وجودنا لدعمك وتشجيعك عندما تكون في الظلام."}
              </p>
              <h4>{language == false ? "rate us" : "قيمنا"}</h4>
              <p>
                {language == false
                  ? "Certainly, we are equal and believe in the philosophy of humanity, our site does not discriminate between our audience. We highly value credibility, reliability, consistency and trustworthiness. On our way to achieving this, we work through teamwork and teamwork, along with coordinating and collaborating with our audience."
                  : "بالتأكيد ، نحن متساوون ونؤمن بفلسفة الإنسانية ، موقعنا لا يميز بين جمهورنا. نحن نقدر عاليا المصداقية والموثوقية والاتساق والجدارة بالثقة. في طريقنا لتحقيق ذلك ، نعمل من خلال العمل الجماعي والجماعي ، جنبًا إلى جنب مع التنسيق والتعاون مع جمهورنا."}
              </p>
              <p>
                {language == false
                  ? "We expect every member of the DOT VIP community to grow and thrive with us; We will walk hand in hand and take care of you, put your worries and concerns in our pockets, put our problems on our shoulders."
                  : "نتوقع أن ينمو كل عضو في مجتمع DOT VIP ويزدهر معنا ؛ سوف نسير يداً بيد ونعتني بك ونضع همومك واهتماماتك في جيوبنا ، ونضع مشاكلنا على أكتافنا."}
              </p>
              <p>
                {language == false
                  ? "Furthermore, we are open and accept criticism, advice, additional knowledge and relevant academic research."
                  : "علاوة على ذلك ، نحن منفتحون ونقبل النقد والنصائح والمعرفة الإضافية والأبحاث الأكاديمية ذات الصلة."}
              </p>
              <h4>{language == false ? "our mission" : "مهمتنا"}</h4>
              <p>
                {language == false
                  ? "Because we believe in the human spirit, online real estate businesses are to create a wide range of employment opportunities, to predict public potential, to turn them into social benefits, and to reach a high benchmark."
                  : "نظرًا لأننا نؤمن بالروح الإنسانية ، فإن الصغقات العقارية على الإنترنت هي خلق مجموعة واسعة من فرص العمل ، والتنبؤ بالإمكانات العامة ، وتحويلها إلى مزايا اجتماعية ، والوصول إلى نسبة مرجعية عالية."}
              </p>
              <p>
                {language == false
                  ? "If you are interested in putting your dreams on the right track, we are happy to make your dreams a reality"
                  : "إذا كنت مهتمًا بوضع أحلامك على المسار الصحيح ، يسعدنا ان نحول أحلامك إلى حقيقة "}
              </p>
              <Share>
                <div className="text">
                  {arabic == false ? "Share on" : "مشاركه فى"}
                </div>
                <div className="socials">
                  <FacebookShareButton
                    url={currentUrl}
                    title="Facebook"
                    hashtag="#property"
                  >
                    <img
                      src={Faceboook}
                      alt="facebook icon"
                      style={{ height: "39px" }}
                    />
                  </FacebookShareButton>
                  <TwitterShareButton url={currentUrl} title="Twitter">
                    <img
                      src={Twitter}
                      alt="twitter icon"
                      style={{ height: "39px" }}
                    />
                  </TwitterShareButton>
                  <LinkedinShareButton url={currentUrl} title="Linkedin">
                    <img
                      src={Linkedin}
                      alt="linkedin icon"
                      style={{ height: "39px" }}
                    />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={currentUrl} title="Whatsapp">
                    <img
                      src={Whatsapp}
                      alt="whatsapp icon"
                      style={{ height: "39px" }}
                    />
                  </WhatsappShareButton>
                  <TelegramShareButton url={currentUrl} title="telegram">
                    <img
                      src={telegram}
                      alt="whatsapp icon"
                      style={{ height: "39px" }}
                    />
                  </TelegramShareButton>
                  <EmailShareButton
                    url={currentUrl}
                    subject="share page"
                    body={"this is link shared"}
                  >
                    <img
                      src={email}
                      alt="whatsapp icon"
                      style={{ height: "39px" }}
                    />
                  </EmailShareButton>
                </div>
              </Share>
            </div>
            <ReactToPrint
              content={() => componentRef.current}
              trigger={() => (
                <button className="btn btn-primary">Print to PDF!</button>
              )}
            />
          </>
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allNews(limit: 3, sort: { fields: created_at }) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allEvents(limit: 2, sort: { fields: date }) {
      edges {
        node {
          date
          city
          address
          name
          id
          country
          thumbnail {
            url
          }
        }
      }
    }
    allPage(filter: { name: { eq: "home" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    allHero {
      edges {
        node {
          image {
            url
          }
          localImage {
            childImageSharp {
              fixed(width: 2000, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          title
          title_ar
          paragraph
          paragraph_ar
          tagline
          tagline_ar
          link {
            text_ar
            text
            url
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget: allWidget {
      edges {
        node {
          description
          id
          image

          link_text
          link
          name
          title
          type
        }
      }
    }
    widget1: widget(name: { eq: "how-we-works" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
    }
    widget2: widget(name: { eq: "property-type" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget4: widget(name: { eq: "our-latest-news" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget6: widget(name: { eq: "our-latest-events" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default IndexPage
